import "./Loader.scss";

import { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

/**
 * Provides loader for screen
 * @param {*} props - Parent props
 * @returns {Element} - Loader element
 */
const Loader = ({ showTimeout = false, small = false }) => {
    const [requestTimeout, setRequestTimeout] = useState(showTimeout);

    const loaderRef = useRef(null);

    let timeout;

    useEffect(() => {
        // eslint-disable-next-line
        timeout = setTimeout(() => {
            // Don't show timeout for small loaders
            if (!small) {
                setRequestTimeout(true);
            }
        }, 240000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        setRequestTimeout(showTimeout);
    }, [showTimeout]);

    const timeOutJSX = <div> Request Timed out </div>;
    const loaderJSX = (
        <div className={"LoaderContainer"} data-testid={"LoaderContainer"}>
            <div className={"my-auto " + (small ? "small-loader" : "loader")}></div>
        </div>
    );

    const style = {
        "minHeight": "300px"
    };
    if (small) {
        style["minHeight"] = "20px";
    }

    return (
        <div className={"d-flex align-items-center flex-grow-1"} style={style} ref={loaderRef}>
            {requestTimeout ? timeOutJSX : loaderJSX}
        </div>
    );
};

Loader.propTypes = {
    "showTimeout": PropTypes.bool,
    "small": PropTypes.bool
};

export default Loader;
